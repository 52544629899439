const fire = `<i class="ri-fire-fill text-black-80" />`;
const ManageScreening = `<i class="ri-computer-line text-black-80" />`;
const ManageProduction = `<i class="ri-product-hunt-line text-black-80" />`;
const roadMap = `<i class="ri-road-map-line text-black-80" />`;
const luggageCart = `<i class="ri-luggage-cart-line text-black-80" />`;
const dropping = `<i class="ri-dropbox-fill text-black-80" />`;

export default {
  header: "LAB",
  children: [
    {
      id: "blood_shipments",
      title: "Pengiriman Darah",
      iconSvg: luggageCart,
      navLink: "/dashboards/blood-shipments",
      roles: ["ADMIN", "AFTAP"],
    },
    {
      id: "proccessing",
      title: "Laboratorium Pengolahan",
      iconSvg: ManageScreening,
      children: [
        {
          id: "blood_received_processing",
          title: "Penerimaan Darah ",
          iconSvg: luggageCart,
          navLink: "/lab/processing/blood-received",
          roles: ["ADMIN", "KOMPONEN"],
        },
        {
          id: "sieving",
          title: "Penyileran",
          navLink: "/lab/processing/sieving",
          roles: ["ADMIN", "KOMPONEN"],
        },
        {
          id: "weighing",
          title: "Penimbangan",
          navLink: "/lab/processing/weighing",
          roles: ["ADMIN", "KOMPONEN"],
        },
        {
          id: "centrifugation",
          title: "Pemutaran",
          navLink: "/lab/processing/centrifugation",
          roles: ["ADMIN", "KOMPONEN"],
        },
        {
          id: "component-separation",
          title: "Pemisahan Komponen",
          navLink: "/lab/processing/component-separation",
          roles: ["ADMIN", "KOMPONEN"],
        },
        {
          id: "labeling-and-weighing",
          title: "Pelabelan dan Penimbangan",
          navLink: "/lab/processing/blood-results",
          roles: ["ADMIN", "KOMPONEN"],
        },
      ],
    },
    {
      id: "screening",
      title: "Laboratorium IMLTD",
      iconSvg: ManageScreening,
      children: [
        {
          id: "blood_received_IMLTD",
          title: "Penerimaan Darah ",
          iconSvg: luggageCart,
          navLink: "/lab/IMLTD/blood-received",
          roles: ["ADMIN", "IMLTD"],
        },
        {
          id: "list-screening",
          title: "List Screening",
          navLink: "/lab/IMLTD/list_screenings_imltd",
          roles: ["ADMIN", "IMLTD"],
        },
        {
          id: "non-reactive-screening",
          title: "Darah Non-Reaktif",
          navLink: "/lab/IMLTD/view_non_reactive",
          roles: ["ADMIN", "IMLTD"],
        },
        {
          id: "reactive-screening",
          title: "Darah Reaktif",
          navLink: "/lab/IMLTD/view_reactive",
          roles: ["ADMIN", "IMLTD"],
        },
      ],
    },
    {
      id: "kgd",
      title: "Laboratorium KGD",
      iconSvg: ManageScreening,
      children: [
        {
          id: "blood_received_KGD",
          title: "Penerimaan Darah",
          navLink: "/lab/KGD/blood-received",
          roles: ["ADMIN", "KGD"],
        },
        {
          navLink: "/lab/KGD/confirm-blood-type",
          title: "Konfirmasi Golongan Darah",
          name: "confirm_blood_type",
          roles: ["ADMIN", "KGD"],
        },
        {
          navLink: "/lab/KGD/screening-antibody",
          title: "Screening Antibody",
          name: "screening-antibody",
          roles: ["ADMIN", "KGD"],
        },
      ],
    },
    {
      id: "produksi",
      title: "Laboratorium Prolis",
      iconSvg: ManageProduction,
      navLink: "/lab/prolis",
      roles: ["ADMIN", "PROLIS"],
    },
    {
      id: "pemusnahan darah",
      title: "pemusnahan darah",
      iconSvg: fire,
      navLink: "/lab/pemusnahan-darah",
      roles: ["ADMIN", "AFTAP", "KOMPONEN", "KGD", "IMLTD", "PROLIS"],
    },
    {
      id: "dropping",
      title: "Dropping Darah",
      iconSvg: dropping,
      navLink: "/lab/dropping",
      roles: ["ADMIN", "PROLIS"],
    },
    {
      id: "tracking",
      title: "Pelacakan Darah",
      iconSvg: roadMap,
      navLink: "/lab/blood-tracking",
      roles: ["ADMIN", "AFTAP", "KOMPONEN", "KGD", "IMLTD", "PROLIS"],
    },
  ],
};

const financeLogo = `<i class="ri-money-dollar-box-fill text-black-80" />`

export default {
  header: 'Finance',
  children: [
    {
      id: 'management_cash',
      title: 'Pengelolaan Kas',
      iconSvg: financeLogo,
      navLink: '/finance/cash-management',
      roles: ['ADMIN', 'FINANCE'],
    },
  ],
}

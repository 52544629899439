<template>
  <div
    class="hp-header-logo d-flex flex-row justify-content-between align-items-center"
  >
    <b-link to="/" class="position-relative d-flex flex-column">
      <img
        v-bind:src="require('@/assets/img/logo/logo.svg')"
        alt="logo"
        width="34"
        height="34"
      />
    </b-link>
    <p
      class="font-weight-medium ml-3 mb-0"
      style="white-space: nowrap; font-size: 22px"
    >
      Admission <span class="text-danger">Bloods</span>
    </p>
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
};
</script>

<template>
  <div
    class="user-header d-flex align-items-center ml-lg-auto justify-content-between px-2"
    style="border-radius: 8px"
    @click.stop="toggleDropdown"
  >
    <div class="d-flex align-items-center">
      <b-avatar
        variant="none"
        :text="getInitials(name)"
        size="32px"
        class="rounded hp-bg-info-4 mx-2"
      />
      <div class="user-info mt-3 d-none d-lg-block">
        <p class="mb-0">{{ name }}</p>
        <p class="text-danger-1">{{ role }}</p>
      </div>
    </div>
    <b-icon icon="caret-down-fill" class="hp-cursor-pointer mr-2"></b-icon>

    <div v-show="showDropdown" class="show-dropdown">
      <div class="dropdown-content">
        <p
          class="h5 font-weight-medium hp-text-color-black-100 hp-text-color-dark-0 mb-16"
        >
          Profile Settings
        </p>
        <div class="d-block d-lg-none">
          <p class="mb-0">{{ name }}</p>
          <p class="text-danger-1">{{ role }}</p>
        </div>
        <b-link
          class="hp-p1-body font-weight-medium hp-hover-text-color-primary-2"
          @click="logout"
        >
          <i class="ri-logout-circle-r-line mx-3" /> Logout
        </b-link>
        <div class="divider mt-18 mb-16"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar, BIcon, BLink } from "bootstrap-vue";

export default {
  components: {
    BAvatar,
    BIcon,
    BLink,
  },
  data() {
    return {
      showDropdown: false,
      name: "",
      role: "",
    };
  },
  mounted() {
    document.addEventListener("click", this.handleOutsideClick);
    const userRole = JSON.parse(localStorage.getItem("userInfo"));
    this.name = userRole.name;
    this.role = userRole.role;
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
  methods: {
    handleOutsideClick(event) {
      if (!this.$el.contains(event.target)) {
        this.showDropdown = false;
      }
    },
    toggleDropdown(event) {
      event.stopPropagation(); // Menghentikan event untuk bubble ke atas
      console.log("Dropdown clicked");
      this.showDropdown = !this.showDropdown;
      console.log(this.showDropdown);
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
      this.$router.push("/pages/authentication/login");
    },
    getInitials(name) {
      const words = name.trim().split(" ");
      if (words.length > 1) {
        return (words[0][0] + words[1][0]).toUpperCase();
      } else {
        return name.substring(0, 2).toUpperCase();
      }
    },
  },
};
</script>

<style scoped>
.user-header {
  position: relative;
  cursor: pointer;
  background-color: white;
  width: 15rem;
}

.show-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  z-index: 1000;
  display: block; /* Tambahkan ini sementara untuk debugging */
}

.dropdown-content {
  padding: 1rem;
  min-width: 235px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #f9f9f9;
}

@media (max-width: 992px) {
  .user-info {
    display: none !important;
  }
  .show-dropdown .dropdown-content {
    padding: 0.5rem;
  }
  .user-header {
    background-color: transparent;
    width: 4rem;
  }
}
</style>

const manageInventory = '<i class="ri-briefcase-4-fill text-black-80" />';
const barcode = '<i class="ri-barcode-line text-black-80" />';
const manageStaff = '<i class="ri-admin-fill text-black-80" />';
const ManageHospital = `
  <i class="ri-hospital-line text-black-80" />
`;

export default {
  header: "Master Data",
  children: [
    {
      id: "pengelolaan-rs-dropping",
      title: "Pengelolaan Daftar Rumah Sakit",
      iconSvg: ManageHospital,
      navLink: "/dashboards/pengelolaan-daftar-rs",
      roles: ["ADMIN"],
    },
    {
      id: "pengelolaan-staff",
      title: "Pengelolaan Staff",
      iconSvg: manageStaff,
      navLink: "/dashboards/pengelolaan-staff",
      roles: ["ADMIN"],
    },
    {
      id: "pengelolaan-logistik",
      title: "Pengelolaan Inventory",
      iconSvg: manageInventory,
      children: [
        {
          id: "vendor",
          title: "Manage Vendor",
          navLink: "/master-data/vendors",
          roles: ["ADMIN", "LOGISTIK"],
        },
        {
          id: "category-inventory",
          title: "Kategori barang",
          navLink: "/master-data/categoryinventorys",
          roles: ["ADMIN", "LOGISTIK"],
        },
        {
          id: "list-inventory",
          title: "Daftar barang",
          navLink: "/master-data/barang",
          roles: ["ADMIN", "LOGISTIK"],
        },
        {
          id: "list-inventory-goods",
          title: "Barang Masuk",
          navLink: "/master-data/barang-masuk",
          roles: ["ADMIN", "LOGISTIK"],
        },
        {
          id: "list-outgoing-goods",
          title: "Barang Keluar",
          navLink: "/master-data/barang-keluar",
          roles: ["ADMIN", "LOGISTIK"],
        },
      ],
    },
    {
      id: "list-barcode",
      iconSvg: barcode,
      title: "Daftar Barcode",
      navLink: "/dashboards/list-barcode",
      roles: ["ADMIN", "LOGISTIK"],
    },
    {
      id: "list-request",
      iconSvg: barcode,
      title: "Daftar Permintaan",
      navLink: "/dashboards/list-demand",
      roles: ["ADMIN", "LOGISTIK"],
    },
    {
      id: "available-barcode",
      iconSvg: barcode,
      title: "Stok Barcode",
      navLink: "/dashboards/available-barcodes",
      roles: ["ADMIN", "LOGISTIK"],
    },
  ],
};

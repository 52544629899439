function filterMenuItems(userRole, sidebarItems) {
  if (Array.isArray(sidebarItems)) {
    return sidebarItems.filter(item => {
      if (!item.roles || item.roles.includes(userRole) || userRole === 'ADMIN') {
        if (item.children) {
          const filteredChildren = filterMenuItems(userRole, item.children)
          return filteredChildren.length > 0 ? { ...item, children: filteredChildren } : null
        }
        return item
      }
      return false
    }).filter(Boolean) // Remove null items
  } if (typeof sidebarItems === 'object' && sidebarItems !== null) {
    const filteredChildren = sidebarItems.children ? filterMenuItems(userRole, sidebarItems.children) : []
    return {
      ...sidebarItems,
      children: filteredChildren,
    }
  }
  return []
}

export default filterMenuItems

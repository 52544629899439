import filterMenuItems from "@/navigation/filterMenuItems";
import dashboards from "./dashboards";
import applications from "./applications";
import pages from "./pages";
import userInterface from "./user-interface";
import masterData from "./master-data";
import laporan from "./laporan";
import lab from "./lab";
import finance from "./finance";
// import dropping from "./dropping";

const getNavigations = (userRole) =>
  [
    filterMenuItems(userRole, dashboards),
    filterMenuItems(userRole, applications),
    filterMenuItems(userRole, masterData),
    filterMenuItems(userRole, pages),
    filterMenuItems(userRole, userInterface),
    filterMenuItems(userRole, lab),
    filterMenuItems(userRole, finance),
    // filterMenuItems(userRole, dropping),
    filterMenuItems(userRole, laporan),
  ].filter((item) => item && item.children && item.children.length > 0);

export default getNavigations;

<template>
  <div
      class="hp-header-text-info col col-lg-14 col-xl-16 hp-header-start-text flex-column"
  >
    <h2
        class="hp-header-start-text-item hp-h2-line-height font-weight-normal hp-text-color-black-100 hp-text-color-dark-0 mb-3 lh-1"
    >
      Selamat Datang, {{ name }} 👋
    </h2>
    <p class="hp-input-label font-weight-medium">
      Status analisis anda ada dibawah ini
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: ""
    }
  },
  mounted() {
    const userRole = JSON.parse(localStorage.getItem("userInfo"));
    this.name = userRole.username;
  }
}
</script>

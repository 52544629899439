// import filterMenuItems from "./filterMenuItems";

const TransaksiKeluarIcon = `<i class="ri-hand-coin-line text-black-80"></i>`;

const DashboardIcon = `
<i class="ri-dashboard-fill text-black-80" />
`;
const ManageUserIcon = `
<i class="ri-group-line text-black-80" />
`;
const ManageInventory = `
<i class="ri-briefcase-4-fill text-black-80" />
`;
const ManageBloodVanIcon = `
<i class="ri-car-line text-black-80" />
`;

const ManageQuisionerIcon = `
<i class="ri-question-line text-black-80" />
`;

const ManageRegistrationIcon = `
  <i class="ri-newspaper-line text-black-80" />
`;

const BloodBagIcon = `
  <i class="ri-first-aid-kit-line text-black-80" />
`;

const ReportIcon = `
  <i class="ri-file-list-line text-black-80" />
`;

const ManageHospital = `
  <i class="ri-hospital-line text-black-80" />
`;

const ScheduleIcon = `
 <i class="ri-time-line text-black-80" />
`;

const BloodCategoryIcon = `
 <i class="ri-drop-line text-black-80" />
`;

const Patient = `
 <i class="ri-mental-health-line text-black-80" />
`;

const BloodbagsSellingIcon =
  '<i class="ri-shopping-cart-line text-black-80" />';

const dropBox = `<i class="ri-dropbox-fill text-black-80" />`;

// Function to filter menu items based on the user role
// !TODO : Buat fungsi getMenuItems sebagai helpers agar reusable

const sidebarItems = [
  {
    id: "analytics",
    title: "Dashboard",
    iconSvg: DashboardIcon,
    navLink: "/dashboards/analytics",
    roles: ["ADMIN"],
  },
  {
    id: "manage-user",
    title: "Kelola Pendonor",
    iconSvg: ManageUserIcon,
    navLink: "/dashboards/users",
    roles: ["ADMIN", "REGISTER", "ALLROLE"],
  },
  {
    id: "manage-donation-registration",
    title: "Aktivasi Pendonor",
    iconSvg: ManageRegistrationIcon,
    navLink: "/dashboards/donations",
    roles: ["ADMIN", "REGISTER", "ALLROLE"],
  },
  {
    id: "manage-doctor",
    title: "Aktivasi Pendonor Dokter",
    iconSvg: ManageRegistrationIcon,
    navLink: "/dashboards/dokter",
    roles: ["ADMIN", "DOCTOR", "ALLROLE"],
  },
  {
    id: "manage-HB-registration",
    title: "Aktivasi Pendonor HB",
    iconSvg: ManageRegistrationIcon,
    navLink: "/dashboards/HB",
    roles: ["ADMIN", "HB", "ALLROLE"],
  },
  {
    id: "manage-Aftap-registration",
    title: "Aktivasi Pendonor Aftap",
    iconSvg: ManageRegistrationIcon,
    navLink: "/dashboards/aftap",
    roles: ["ADMIN", "AFTAP", "ALLROLE", "REGISTER"],
  },
  // {
  //   id: "blood-bag",
  //   title: "Blood Bags",
  //   iconSvg: BloodBagIcon,
  //   navLink: "/dashboards/bloodbags",
  //   roles: ["ADMIN"],
  // },
  // {
  //   id: "manage-bloodbags-selling",
  //   title: "Distribusi Bloodbags",
  //   iconSvg: BloodbagsSellingIcon,
  //   navLink: "/dashboards/bloodbags-sellings",
  //   roles: ["ADMIN"],
  // },
  {
    id: "manage-rumah-sakit",
    title: "Kelola Rumah Sakit",
    iconSvg: ManageHospital,
    navLink: "/dashboards/hospitals",
    roles: ["ADMIN"],
  },
  {
    id: "manage-bloodvan",
    title: "Kelola Mobil Unit",
    iconSvg: ManageBloodVanIcon,
    navLink: "/dashboards/bloodvans",
    roles: ["ADMIN"],
  },
  {
    id: "manage-list-user-examination",
    title: "Kelola Data Pendonor",
    iconSvg: ManageRegistrationIcon,
    navLink: '/dashboards/list-user-examinations',
    roles: ["REGISTER", "ADMIN"],
  },
  // {
  //   iconSvg: ManageInventory,
  //   id: "manage-inventory-goods",
  //   title: "Blood Inventory",
  //   roles: ["ADMIN"],
  //   children: [
  //     {
  //       id: "manage-inventory-goods",
  //       title: "Kelola Barang Inventory",
  //       navLink: "/dashboards/inventory-goods",
  //       roles: ["ADMIN", "HB"],
  //     },
  //     {
  //       id: "create-inventory-goods",
  //       title: "Tambah Barang Masuk",
  //       navLink: "/master-data/barang-masuk/tambah",
  //       roles: ["ADMIN", "HB"],
  //     },
  //     {
  //       id: "create-outgoing-goods",
  //       title: "Tambah Barang Keluar",
  //       navLink: "/dashboards/create-outgouing-goods",
  //       roles: ["ADMIN", "HB"],
  //     },
  //   ],
  // },
  // {
  //   id: "patient",
  //   title: "Pasien",
  //   iconSvg: Patient,
  //   navLink: "/dashboards/patients",
  //   roles: ["ADMIN"],
  // },
  // {
  //   id: "patient-service",
  //   title: "Patient Service",
  //   iconSvg: TransaksiKeluarIcon,
  //   navLink: "/dashboards/patient_services",
  //   roles: ["ADMIN"],
  // },
];

export default {
  header: "Dashboards",
  children: sidebarItems,
};

const laporan = `<i class="ri-newspaper-line text-black-80"></i>`;
const mobilUnit = `<i class="ri-truck-line text-black-80"></i>`;
const donor = `<i class="ri-user-line text-black-80"></i>`;
const screening = `<i class="ri-sip-line text-black-80"></i>`;
const stock = `<i class="ri-shopping-cart-2-line text-black-80"></i>`;
const darah = `<i class="ri-contrast-drop-2-line text-black-80"></i>`;
const dropping = `<i class="ri-dropbox-fill text-black-80"></i>`;
const prolis = `<i class="ri-product-hunt-line text-black-80"></i>`;

// Function to filter menu items based on the user role
// !TODO : Buat fungsi getMenuItems sebagai helpers agar reusable

export default {
  header: "laporan",
  children: [
    // {
    //   iconSvg: laporan,
    //   id: "laporan",
    //   title: "laporan",
    //   roles: ["ADMIN"],
    //   children: [
    //     {
    //       id: "rekapitulasi-service",
    //       title: "Rekapitulasi Service",
    //       navLink: "/laporan/inventory-goods",
    //       roles: ["ADMIN"],
    //     },
    //     {
    //       id: "rekapitulasi-subsidi",
    //       title: "rekapitulasi-subsidi",
    //       navLink: "/laporan/rekapitulasi-subsidi",
    //       roles: ["ADMIN"],
    //     },
    //     {
    //       id: "rekapitulasi-transaksi-cash",
    //       title: "rekapitulasi transaksi cash",
    //       navLink: "/rekapitulasiTransaksiCash",
    //       roles: ["ADMIN"],
    //     },
    //     {
    //       id: "tagihan-dropping",
    //       title: "tagihan dropping",
    //       navLink: "/laporan/tagihan-dropping",
    //       roles: ["ADMIN"],
    //     },
    //   ],
    // },
    // {
    //   iconSvg: donor,
    //   id: "donor",
    //   title: "Rekapitulasi Donor",
    //   roles: ["ADMIN"],
    //   children: [
    //     {
    //       id: "rekapitulasi-donor-terisi-atau-tidak",
    //       title: "Rekapitulasi Donor",
    //       navLink: "/laporanDonorTerisiAtauTidak",
    //       roles: ["ADMIN"],
    //     },
    //     {
    //       id: "rekapitulasi-stok-barang",
    //       title: "Donor Darah Lengkap",
    //       navLink: "/laporanDonorDarah",
    //       roles: ["ADMIN"],
    //     },
    //   ],
    // },
    {
      iconSvg: mobilUnit,
      id: "mobil-unit",
      title: "Rekapitulasi Mobil Unit",
      navLink: "/rekapitulasiMUDetail",
      roles: ["ADMIN"],
    },
    {
      iconSvg: mobilUnit,
      id: "mobil-unit-excel",
      title: "Rekapitulasi Mobil Unit (Excel)",
      navLink: "/rekapitulasiMUExcel",
      roles: ["ADMIN"],
    },
    // {
    //   iconSvg: screening,
    //   id: "screening",
    //   title: "Rekapitulasi Screening",
    //   navLink: "/rekapitulasiScreening",
    //   roles: ["ADMIN"],
    // },
    // {
    //   iconSvg: darah,
    //   id: "darah",
    //   title: "Rekapitulasi Darah",
    //   roles: ["ADMIN"],
    //   children: [
    //     {
    //       id: "darah-karantina",
    //       title: "Laporan Darah Karantina",
    //       navLink: "/rekapitulasiDarahKarantina",
    //       roles: ["ADMIN"],
    //     },
    //   ],
    // },
    // {
    //   iconSvg: darah,
    //   id: "darah",
    //   title: "Rekapitulasi Pengumpulan Darah",
    //   roles: ["ADMIN"],
    //   navLink: "/rekapitulasiDarahMasuk",
    // },
    {
      iconSvg: darah,
      id: "pemusnahan-darah",
      title: "Rekapitulasi Pemusnahan Darah",
      navLink: "/rekap-pemusnahan-darah",
      roles: ["ADMIN", "PROLIS"],
    },
    {
      iconSvg: stock,
      id: "laporan-stok-barang",
      title: "Laporan Stok Barang",
      navLink: "/master-data/laporan-stok-barang",
      roles: ["ADMIN", "LOGISTIK"],
    },
    {
      iconSvg: prolis,
      id: "rekap-prolis",
      title: "Rekapitulasi prolis",
      navLink: "/rekap-prolis",
      roles: ["ADMIN", "PROLIS"],
    },
    {
      iconSvg: dropping,
      id: "rekap-dropping",
      title: "Rekapitulasi Dropping",
      navLink: "/rekap-dropping",
      roles: ["ADMIN", "PROLIS"],
    },
  ],
};
